import { sortBy } from "lodash"

export const roles = {
  manager: { label: 'Administrador' },
  consultant: { label: 'Consultor' },
  customer: { label: 'Cliente' }
}

export const typesUseDataType = {
  'NAO': 'Pastagens o ano todo',
  'ILP': 'Integração Lavoura Pecuária',
  'ILF': 'integração Lavoura Pecuária Floresta',
  'PE': 'Pastagem ecológica',
  'SP': 'Silvipastoril'
}

export const typesUseDataTypeOptions = dataTypeToOptions(typesUseDataType)

export const fertilizedsDataType = {
  'NAO_ADUBADO': 'Não Adubado',
  'ADUBADO_REGULARMENTE': 'Adubado Regularmente'
}

export const fertilizedsDataTypeOptions = dataTypeToOptions(fertilizedsDataType)

export const irrigationsDataType = {
  'IRRIGADO': 'Irrigado',
  'SEQUEIRO': 'Sequeiro'
}

export const irrigationsDataTypeOptions = dataTypeToOptions(irrigationsDataType)

export const satellitesDataType = {
  CAMPO: 'C',
  RADAR: '2R',
  OPTICO: '1O',
  NDVI: 'NDVI'
}

export const satellitesImageDataTypeOptions = dataTypeToOptions(satellitesDataType)
export const satellitesDataTypeOptions = dataTypeToOptions(satellitesDataType).filter(({ value }) => value !== 'NDVI' )

export const calcsDataType = {
  'NUMBER_OF_HEADS_THAT_FIT_IN_MODULE': 'Quantas cabeças cabem neste módulo',
  'NUMBER_OF_HEADS_PER_PERFORMANCE_GOAL': 'Quantidade de cabeças por meta de desempenho',
  'WHAT_IS_THE_EXPECTED_WEIGHT_GAIN_IN_THIS_BATCH': 'Qual o ganho de peso esperado neste lote',
}
export const calcsDataTypeOptions = dataTypeToOptions(calcsDataType)

export const daysOccupationDataType = {
  0.33: '8 horas/piquete',
  0.5: '12 horas/piquete',
  1: '1 dia/piquete',
  2: '2 dias/piquete',
  3: '3 dias/piquete',
  4: '4 dias/piquete',
  5: '5 dias/piquete',
  6: '6 dias/piquete',
  7: '7 dias/piquete',
}
export const daysOccupationDataTypeOptions = sortBy(dataTypeToOptions(daysOccupationDataType), 'value')

export const movementDataType = {
  'FECHAMENTO': 'Fechamento',
  'ENTRADA': 'Entrada',
  'SAIDA': 'Saída',
  'PESAGEM': 'Nova pesagem',
  'OCUPACAO': 'Alterar tempo de ocupação',
  'SUPLEMENTACAO': 'Ajuste de Suplemento',
  'MUDANÇA_DE_MODULO': 'Mudança de módulo',
  'TROCA_DE_MODULO': 'Troca de módulo',
  'MUDANCA_CATEGORIA': 'Mudança de categoria',
  'ENCERRAR_LOTE': 'Encerrar o lote (com 0 animais)',
  'REDUCAO_DE_MODULO': 'Redução de módulo'
}
export const movementDataTypeOptions = dataTypeToOptions(movementDataType).filter(({ value }) => value !== 'FECHAMENTO' )

export const supplementDataType = {
   value: 'SUPLEMENTACAO', text: 'kg de MN/cab/dia', type: 'SUPLEMENTACAO'
  }
export const supplementDataTypeOptions = dataTypeToOptions(supplementDataType)

export const weighingDataType = {
  'BALANCA_INDIVIDUAL': 'Balança individual',
  'BALANCA_GRUPO': 'Balança em grupo' ,
  'BALANCA_CAMINHAO': 'Balança caminhão' ,
  'ESTIMATIVA_VISUAL': 'Estimativa – olho' ,
  'ESTIMATIVA_FITA': 'Estimativa – fita' ,
}
export const weighingDataTypeOptions = dataTypeToOptions(weighingDataType)

export const movementCategoryDataTypeOptions = [
  { value: 'INICIO_MES', text: 'Início de mês', type: 'FECHAMENTO' },
  { value: 'ENTRADA_COMPRA', text: 'Compra', type: 'ENTRADA' },
  { value: 'ENTRADA_NASCIMENTO', text: 'Nascimento', type: 'ENTRADA' },
  { value: 'ENTRADA_OUTRO_PASTO', text: 'Vindo de outro pasto não cadastrado', type: 'ENTRADA' },
  { value: 'ENTRADA_OUTRO_LOTE', text: 'Vindo de outro módulo do Pastoreio de Precisão', type: 'ENTRADA' },
  { value: 'ENTRADA_AJUSTE', text: 'Ajuste', type: 'ENTRADA' },
  { value: 'SAIDA_VENDA', text: 'Venda', type: 'SAIDA' },
  { value: 'SAIDA_ABATE', text: 'Abate', type: 'SAIDA' },
  { value: 'SAIDA_OUTRO_PASTO', text: 'Indo para outro pasto não cadastrado', type: 'SAIDA' },
  { value: 'SAIDA_OUTRO_LOTE', text: 'Indo para outro módulo do Pastoreio de Precisão', type: 'SAIDA' },
  { value: 'SAIDA_MORTE', text: 'Morte', type: 'SAIDA' },
  { value: 'SAIDA_AJUSTE', text: 'Ajuste', type: 'SAIDA' },
  { value: 'SAIDA_DESMAME_LOTE', text: 'Desmame - Outro Módulo', type: 'SAIDA' },
  { value: 'SAIDA_DESMAME_OUTRO', text: 'Desmame - Outro Pasto não cadastrado', type: 'SAIDA' },
  { value: 'SAIDA_DESMAME_VENDA', text: 'Desmame - Venda', type: 'SAIDA' },
]
export const movementCategoryDataType = optionsToDataType(movementCategoryDataTypeOptions)

export const geneticPatternsDataType = {
  'ZEBUINO': 'Zebuíno',
  'CRUZAMENTO_INDUSTRIAL': 'Cruzamento industrial',
  'CRUZAMENTO_LEITEIRO': 'Cruzado leiteiro'
}
export const geneticPatternsDataTypeOptions = dataTypeToOptions(geneticPatternsDataType)

export const categoriesAnimalDataType = {
  'MACHO_12': 'Macho até 12m',
  'MACHO_13-24': 'Macho 13-24m',
  'MACHO_+24': 'Macho +24m',
  'FEMEA_12': 'Fêmea até 12m',
  'FEMEA_13-24': 'Fêmea 13-24m',
  'FEMEA_+24': 'Fêmea +24m',
  'BEZERRO': 'Bezerro/a ao pé',
}
export const categoriesAnimalDataTypeOptions = dataTypeToOptions(categoriesAnimalDataType)

export const categoriesComplementaryDataTypeOptions = [
  { value: 'FEMEA_13-24_DESAFIO', text: 'Novilha desafio', category: 'FEMEA_13-24', changeCategory: ['FEMEA_13-24_', 'FEMEA_+24_NOVILHA_', 'FEMEA_+24_PARIDA_PRENHA'] },
  { value: 'FEMEA_13-24_PRENHA', text: 'Novilha prenha', category: 'FEMEA_13-24', changeCategory: ['FEMEA_13-24_', 'FEMEA_+24_NOVILHA_', 'FEMEA_+24_PARIDA_PRENHA'] }, 
  { value: 'FEMEA_13-24_DESCARTE', text: 'Novilha engorda', category: 'FEMEA_13-24', changeCategory: ['FEMEA_13-24_', 'FEMEA_+24_NOVILHA_', 'FEMEA_+24_PARIDA_PRENHA'] },
  { value: 'FEMEA_13-24_RECRIA', text: 'Novilha recria', category: 'FEMEA_13-24', changeCategory: ['FEMEA_13-24_', 'FEMEA_+24_NOVILHA_', 'FEMEA_+24_PARIDA_PRENHA'] },
  { value: 'FEMEA_+24_NOVILHA_PRENHA', text: 'Novilha prenha', category: 'FEMEA_+24', changeCategory: ['FEMEA_+24_NOVILHA_', 'FEMEA_+24_PARIDA_PRENHA'] },
  { value: 'FEMEA_+24_NOVILHA_VAZIA', text: 'Novilha vazia', category: 'FEMEA_+24', changeCategory: ['FEMEA_+24_NOVILHA_', 'FEMEA_+24_PARIDA_PRENHA'] },
  { value: 'FEMEA_+24_NOVILHA_DESCARTE', text: 'Novilha engorda', category: 'FEMEA_+24', changeCategory: ['FEMEA_+24_NOVILHA_', 'FEMEA_+24_PARIDA_PRENHA'] },
  { value: 'FEMEA_+24_PARIDA_PRENHA', text: 'Vaca parida', category: 'FEMEA_+24', changeCategory: ['FEMEA_+24_SOLTEIRA_VAZIA', 'FEMEA_+24_PARIDA_PRENHA'] },
  { value: 'FEMEA_+24_SOLTEIRA_VAZIA', text: 'Vaca solteira', category: 'FEMEA_+24', changeCategory: ['FEMEA_+24_SOLTEIRA_VAZIA', 'FEMEA_+24_PARIDA_PRENHA'] },
  { value: 'FEMEA_+24_DESCARTE', text: 'Vaca descarte', category: 'FEMEA_+24', changeCategory: ['FEMEA_+24_PARIDA_PRENHA'] },
  { value: 'FEMEA_+24_PARIDA_VAZIA', text: 'Vaca parida vazia', category: 'FEMEA_+24', changeCategory: ['FEMEA_+24_SOLTEIRA_VAZIA', 'FEMEA_+24_PARIDA_PRENHA'] },
  { value: 'FEMEA_+24_SOLTEIRA_PRENHA', text: 'Vaca solteira prenha', category: 'FEMEA_+24', changeCategory: ['FEMEA_+24_SOLTEIRA_VAZIA', 'FEMEA_+24_PARIDA_PRENHA'] },
]
export const categoriesComplementaryDataType = optionsToDataType(categoriesComplementaryDataTypeOptions)

export const mainActivityDataTypeOptions = [
  { value: 'CRIA', text:'Cria (produção de bezerros)'},
  { value: 'RECRIA_E_ENGORDA', text:'Recria e engorda'}
]
export const mainActivityDataType = optionsToDataType(categoriesComplementaryDataTypeOptions)

function dataTypeToOptions(dataType) {
  return Object.entries(dataType).map(([key, val]) => ({
    text: val,
    value: key
  }))
}

function optionsToDataType(options) {
  return options.reduce((acc, curr) => {
    acc[curr.value] = curr.text
    return acc
  }, {})
}
