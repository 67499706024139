var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-5"},[_vm._v("Ajuste de medição")]),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"must-sort":"","headers":_vm.headers,"items":_vm.list,"options":_vm.options,"server-items-length":_vm.listCount,"loading":_vm.loading,"items-per-page":10},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Procurar por fazenda ou módulo/piquete","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-text-field',{staticClass:" ml-4 shrink",attrs:{"type":"date","label":"Data","hide-details":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.$refs.modalImport.$emit('create')}}},[_vm._v(" importar ")]),_c('v-btn',{attrs:{"color":"primary","dark":"","to":"/medicoes/new"}},[_vm._v(" Novo ")])],1)]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.satellitesDataType[item.type])+" ")]}},{key:"item.captured_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(new Date(item.captured_at),'DD/MM/YYYY HH:mm'))+" ")]}},{key:"item.info_lost",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("percent")(item.info_lost,1))+" ")]}},{key:"item.visible_ms",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number:decimals")(item.visible_ms,0))+" ")]}},{key:"item._actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":("/medicoes/" + (item.id))}},[_c('v-list-item-title',[_vm._v("Editar"+_vm._s(item.title))])],1),_c('confirmation',{attrs:{"message":"Tem certeza que deseja remover está medição?"},on:{"success":function () { return _vm.remove(item.id); }}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Remover"+_vm._s(item.title))])],1)],1),(item.import_code)?_c('confirmation',{attrs:{"message":"Tem certeza que deseja remover está importação?"},on:{"success":function () { return _vm.removeImportCode(item.import_code); }}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v("Remover importação"+_vm._s(item.title))])],1)],1):_vm._e()],1)],1)]}}],null,true)})],1),_c('import',{ref:"modalImport",on:{"success":function($event){return _vm.fetch()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }